import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "../styles/postslist.css";
import { connect } from "react-redux";
import BounceLoader from 'react-spinners/DotLoader'

import { retrieveAllPosts } from "../redux/actions/postActions";

const PostList = (props) => {
  useEffect(() => {
    props.retrieveAllPosts();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="bd-flex">
      {props.posts != null && props.posts.length > 0 ? (
        props.posts.map((post, i) => {
          return (
            <div key={i} className="blog__post">
              <div className="blog__post__img">
                <img
                  src={post.mainImage.asset.url}
                  alt={`Blog Post img from post`}
                />
              </div>
              <div className="blog__post__info">
                <div className="blog__post__date">
                  <span>{post.publishedAt.substring(0, 10)}</span>
                </div>
                <h1 className="blog__post__title">{post.title}</h1>
                <p className="blog__post__text">{`${post.body[0].children[0].text
                  .split(" ")
                  .splice(0, 16)
                  .join(" ")}...`}</p>
                <Link
                  to={`/blog/${post.slug.current}`}
                  className="blog__post__cta"
                  data={post}
                >
                  Read more
                </Link>
              </div>
            </div>
          );
        })
      ) : (
        <div className="bd-flex">
          <BounceLoader size={20} color={'#fff'}/>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  posts: state.posts.list,
});

export default connect(mapStateToProps, { retrieveAllPosts })(PostList);
